import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import FirstScreenSection from '../../components/FirstScreenSection';
import GradesSection from '../../components/GradesSection';
import Calculator from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import OurFeaturesSection from '../../components/OurFeaturesSection';
import {
  SeoTextSection,
  SeoTextHeading,
  SeoTextContent,
} from '../../components/SeoText';
import CTA from '../../components/CTA';

const PoliticalScienceWritingServicePage = () => (
  <Layout
    pageWrapperClassName="political-science-writing-service-page"
    headerClassName="header_white-bg"
  >
    <Helmet
      title="Political Science Essay Writing Service to Cope with Your Learning Issues | TakeAwayEssay.com"
      meta={[
        {
          name: 'description',
          content:
            'We are going to outline the main benefits you’ll get if you deal with us. Place an order on our political science essay writing service to solve your learning issues quickly.',
        },

        {
          property: 'og:title',
          content:
            'Custom Political Science Essay Writing Service at Affordable Prices | TakeAwayEssay.com',
        },
        {
          property: 'og:description',
          content:
            'Custom political science essay writing service at low prices from the best specialists in the field. Order a top-quality paper from specialists, get the grade you need and improve your writing skills. Top-quality papers, 100% uniqueness, urgent deadlines, compliance with requirements.',
        },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:image',
          content: 'https://takeawayessay.com/open-graph-img.jpg',
        },
      ]}
      link={[
        {
          rel: 'canonical',
          href: 'https://takeawayessay.com/political-science-writing-service',
        },
      ]}
    />

    <FirstScreenSection containerClassName="first-screen-section--seo">
      <h2 className="first-screen-section__title first-screen-section__title_seo">
        Down with
        <br className="first-screen-section__title__br" /> essay dictatorship!
      </h2>
      <p className="first-screen-section__heading first-screen-section__heading_seo">
        Political science probably knows no crueler dystopia
        <br className="first-screen-section__heading__br" /> than having to
        write academic essays 24/7
      </p>
    </FirstScreenSection>

    <GradesSection />
    <Calculator />
    <Testimonials nameOfPageArray="home" />
    <OurFeaturesSection />

    <SeoTextSection>
      <SeoTextHeading>
        Political science essay writing service to handle your academic issues
      </SeoTextHeading>

      <SeoTextContent>
        <div className="seo-text-section__text__css-half-column">
          <p>
            While students learn, they may face all kinds of learning
            impediments, as well as some non-academic hardships. Their sum leads
            to disappointing results when a student looks at his or her rating
            at the end of the semester. No one wants to go low, especially when
            one studies such an interesting subject as political science. It is
            a crucial industry and certified experts are always in high demand.
            Definite impediments may spoil the rating, and thus we recommend
            using our pro platform.
          </p>
          <p>
            We are called TakeAwayEssay.com. Our custom political science essay
            writing service reigns in the industry of academic aid for over 10
            years in a row. We always show only the best results for our
            clients. Our achievements include both academic and personal
            satisfaction. When you deal with us, you surely succeed!
          </p>
          <h2 className="h3">Political science help offered by experts</h2>
          <p>
            The very first thing you need to learn about our custom agency is
            the quality of aid. It is always as high as you demand! How is it
            possible, and why are we that confident? We know that our writers
            and editors are skilled, experienced, and effective. They have
            excellent writing, editing, rewriting, outlining, proofreading, and
            other kinds of skills.
          </p>
          <p>
            Our experts offer various kinds of help. You can even request help
            with political science topics for essay. They will create the most
            relevant and captivating ideas to research. They can find evidence
            for you, form reference lists, check the readability of the text,
            and so on. All your wishes will be fulfilled. We help to write
            various pieces of writing:
          </p>
          <ul className="dot-list">
            <li>Essays;</li>
            <li>Resumes;</li>
            <li>Speeches;</li>
            <li>Case studies;</li>
            <li>Dissertations;</li>
            <li>Lab reports;</li>
            <li>Presentations, etc.</li>
          </ul>
          <p>
            You should know that we have 500+ performers! It means you will find
            perfect solvers in the next academic areas:
          </p>
          <ul className="dot-list">
            <li>Technology;</li>
            <li>Computer science;</li>
            <li>Engineering;</li>
            <li>Finance;</li>
            <li>Marketing;</li>
            <li>Economics;</li>
            <li>Literature;</li>
            <li>History;</li>
            <li>Culture;</li>
            <li>Medicine;</li>
            <li>Nursing;</li>
            <li>Chemistry;</li>
            <li>Physics, etc.</li>
          </ul>
          <h2 className="h3">Order a pretty cheap political issues essay</h2>
          <p>
            Professional aid surely has its price, and you will have to pay it.
            Luckily for our customers, they never overpay for a political
            science essay, as well as for any other academic project. Our prices
            are alternatively cheap and are surely affordable for ordinary
            students.
          </p>
          <p>
            We offer full customization of every order, which puts you in full
            charge of the final sum of payment. When you provide your
            requirements, you can adjust the sum according to your budget. The
            main price indicators are your project’s quality, type, size, and
            urgency. When you change any of these demands, you can lower the
            price.
          </p>
          <p>
            Our academic platform also offers great discounts to save up more of
            your earnings. A special refund policy ensures your investments. If
            your helper fails you, all your money will be refunded to the
            fullest.
          </p>
          <h2 className="h3">Get fast and unique support</h2>
          <p>
            Time means a lot to every student because every academic project
            must be finished by a definite date. If a student fails, he or she
            surely loses most or even all possible grades for the violation of
            the deadline. This is one of the most common problems that make
            students seek our aid. We are always glad to meet their time limits.
          </p>
          <p>
            Almost 99% of all our orders were delivered on time! You will not
            find a writing company that has a better result because it doesn’t
            exist. We are the swiftest on the Web! Our writers and editors never
            waste time when they are busy with their orders. They do not delay,
            start working instantly, and apply the most effective time
            management strategies to be on time.
          </p>
          <p>
            We guess you know from your own experience that when a person is in
            a hurry, he/she commonly makes some mistakes. This issue never
            happens with our experts. Their skills are excellent and when they
            speed up, the quality of their texts doesn’t suffer. It will be
            perfect as you demand and delivered without delays.
          </p>
          <p>
            When you order a political essay or any other piece of writing on
            our site, you also enjoy the originality of our writers. All your
            orders will be 100% authentic and creative automatically! All our
            writers and editors stick to this rule. They do whatever is
            necessary to make all texts original, creative, and readable.
          </p>
          <p>
            They also know what phrases and words can potentially plagiarize
            your content. These are never used. Our experts also use various
            plagiarism apps that detect the slightest signs of the non-unique
            lexicon. All plagiarized parts are reworked and improved.
          </p>
          <h2 className="h3">24/7 support and protection</h2>
          <p>
            It is always important to have a reliable agency close at hand. Many
            learners receive tasks all of a sudden. Others remember them when
            it’s pretty late. That is why we function day and night. We accept
            the most urgent orders whenever our aid may be required.
          </p>
          <p>
            We also have a responsive team of supervisors. It consists of clever
            and polite consultants who can be reached in the live chat room. Ask
            your questions about our company to receive fast and plain replies.
          </p>
          <p>
            You may be sure that when you request political science essay topics
            or any other kind of academic support, your private data gets
            protected automatically by our safety protocols. We mean the ones
            provided by the antivirus software we utilize, as well as the ones
            all our workers follow when they deal with our customers.
          </p>
          <p>
            We utilize the most effective software to fight back against all
            kinds of cyber dangers. No hacker will be able to get through our
            reliable firewall. Our system of protection is almost as effective
            as famous blockchain technology. We support various kinds of billing
            methods to provide you with a rich and safe choice to pay for our
            academic assistance.
          </p>
          <h3 className="h3">Wrapping up</h3>
          <p>
            If you have problems with your political science task or have
            problems with quite different subjects, you can always count on us!
            We are a professional custom agency that never gives empty promises.
            If we accept your order and claim that it will be perfect, it will
            surely be so! Be quick to place an instant order straight away!
          </p>
        </div>
      </SeoTextContent>
    </SeoTextSection>

    <CTA btnText="Hire our writers">
      Looks like you might have a job
      <br /> for our guerilla writers
    </CTA>
  </Layout>
);

export default PoliticalScienceWritingServicePage;
